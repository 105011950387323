import { type ReportSet } from 'models/report';
import { type AdminCompanyLogoUploadPostRequestParamsType as ApiAdminCompanyLogoUploadPostParams } from 'pages/api/admin/company/logo-upload';
import { type ApiAdminPodcastGetParams } from 'pages/api/admin/podcast';
import { type ApiAdminRssMetadataGetParams } from 'pages/api/admin/rss-metadata';
import { type ApiAdminRssRedirectGetParams } from 'pages/api/admin/rss-redirect';
import { type ApiRankerGetParams } from 'pages/api/ranker';
import { type ApiRankerPdfGetParams, type ApiRankerPdfGetParamsInput } from 'pages/api/ranker.pdf';
import { type Podcast } from 'report/getReport';
import { type ReportPeriod } from 'report/reportPeriod';

export function getRankerUrl({
    regionId,
    slug,
    period,
}:
    | {
          // all
          regionId: string;
          slug: string;
          period: ReportPeriod;
      }
    | {
          // only region/slug
          regionId: string;
          slug: string;
          period?: undefined;
      }
    | {
          // only region
          regionId: string;
          slug?: undefined;
          period?: undefined;
      }): string {
    let url = `/rankers/${regionId}`;

    if (slug !== undefined) {
        url += '/' + slug;
    }

    if (period !== undefined) {
        url += `/` + period.year + '/' + period.month;
    }

    return url;
}

export function getQueryStringFromRecord(record: Record<string, string | number | undefined>): string {
    const params = new URLSearchParams();

    Object.entries(record).forEach(([key, value]) => {
        if (value === undefined) {
            return;
        }

        params.set(key, value.toString());
    });

    return params.toString();
}

export function getApiRankerUrl(params: ApiRankerGetParams) {
    return `/api/ranker?${getQueryStringFromRecord(params)}`;
}

export function getRankerPdfUrl(params: ApiRankerPdfGetParams) {
    const paramsSelect: Pick<ApiRankerPdfGetParamsInput, 'accessKey' | 'download' | 'locale'> = {
        accessKey: params.accessKey,
        download: params.download?.toString(),
        locale: params.locale,
    };

    return `${getRankerUrl({
        regionId: params.regionId,
        slug: params.slug,
        period: { year: params.year, month: params.month },
    })}/pdf?${getQueryStringFromRecord(paramsSelect)}`;
}

export function getAdminReportsUploadUrl({ reportSet }: { reportSet: ReportSet }) {
    return `/admin/reports/upload/${encodeURIComponent(reportSet.regionId)}/${encodeURIComponent(reportSet.slug)}`;
}

export function getAdminReportsEditUrl(id: number) {
    return `/admin/reports/edit/${encodeURIComponent(id)}`;
}

export function getPodcastImageUrl(podcast: Pick<Podcast, 'id'>) {
    return `/api/podcast-image/${encodeURIComponent(podcast.id)}`;
}

export function getApiAdminCompanyIdUrl(companyId: number) {
    return `/api/admin/company/${encodeURIComponent(companyId)}`;
}

export function getApiAdminCompanyLogoUploadUrl(params: ApiAdminCompanyLogoUploadPostParams) {
    return `/api/admin/company/logo-upload?${getQueryStringFromRecord(params)}`;
}

export function getApiAdminRssMetadataUrl(params: ApiAdminRssMetadataGetParams) {
    return `/api/admin/rss-metadata?${getQueryStringFromRecord(params)}`;
}

export function getApiAdminRssRedirectUrl(params: ApiAdminRssRedirectGetParams) {
    return `/api/admin/rss-redirect?${getQueryStringFromRecord(params)}`;
}

export function getApiAdminPodcastUrl(params: ApiAdminPodcastGetParams) {
    return `/api/admin/podcast?${getQueryStringFromRecord(params)}`;
}
