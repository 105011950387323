import ArrowDownward from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import RemoveIcon from '@mui/icons-material/Remove';
import ReplayIcon from '@mui/icons-material/Replay';
import StarOutlineIcon from '@mui/icons-material/StarOutline';
import { alpha } from '@mui/material';
import * as React from 'react';
import { useIntl } from 'react-intl';
import { type RankChange as RankChangeType } from 'report/reportTypes';
import { theme } from 'styles/mui-theme';
import { TritonTooltip } from 'styles/styles';

const Wrapper = ({
    tooltip,
    ...children
}: React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement> & { tooltip: string }) => (
    <TritonTooltip arrow title={tooltip}>
        <span
            css={{
                display: 'inline-flex',
                alignItems: 'center',
                lineHeight: 1,
            }}
            aria-label={tooltip}
            {...children}
        />
    </TritonTooltip>
);

export const RankChange = ({
    rankChange,
    hideNoChangeIcon,
}: {
    rankChange: RankChangeType;
    hideNoChangeIcon?: boolean;
}): React.ReactElement | null => {
    const intl = useIntl();

    switch (rankChange.type) {
        case 'debut':
            return (
                <Wrapper
                    tooltip={intl.formatMessage({ defaultMessage: 'Debut', description: 'Rank change: debut' })}
                    css={{
                        color: theme.palette.success.light,
                        verticalAlign: 'middle',
                    }}
                >
                    <StarOutlineIcon fontSize="small" />
                </Wrapper>
            );

        case 'return':
            return (
                <Wrapper
                    tooltip={intl.formatMessage({ defaultMessage: 'Return', description: 'Rank change: return' })}
                    css={{
                        verticalAlign: 'middle',
                    }}
                >
                    <ReplayIcon fontSize="small" sx={{ color: alpha(theme.tritonColors.darkBlue, 0.4) }} />
                </Wrapper>
            );

        case 'noChange':
            return !hideNoChangeIcon ? (
                <Wrapper
                    tooltip={intl.formatMessage({ defaultMessage: 'No change', description: 'Rank change: no change' })}
                >
                    <RemoveIcon fontSize="small" sx={{ color: alpha(theme.tritonColors.darkBlue, 0.2) }} />
                </Wrapper>
            ) : null;

        case 'up':
            return (
                <Wrapper
                    tooltip={`${intl.formatMessage({
                        defaultMessage: 'Rank increased',
                        description: 'Rank change: increased',
                    })} ${rankChange.change}`}
                    css={{
                        color: theme.palette.success.light,
                    }}
                >
                    +{rankChange.change} <ArrowUpwardIcon fontSize="small" />
                </Wrapper>
            );

        case 'down':
            return (
                <Wrapper
                    tooltip={`${intl.formatMessage({
                        defaultMessage: 'Rank decreased',
                        description: 'Rank change: decreased',
                    })} ${Math.abs(rankChange.change)}`}
                    css={{
                        color: theme.palette.error.light,
                    }}
                >
                    {rankChange.change} <ArrowDownward fontSize="small" />
                </Wrapper>
            );
    }
};
