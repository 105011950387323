import { FormattedNumber } from 'components/FormattedNumber';

export const ActivePodcasts = ({ value }: { value: number | null }) => {
    // the number of podcasts is capped to 500
    // if value is 500, then display as "500+"
    if (value === 500) {
        return <>500+</>;
    }

    return <FormattedNumber value={value} />;
};
