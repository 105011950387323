import * as React from 'react';

export const ImageFade = (
    props: React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>,
) => {
    const imgRef = React.useRef<HTMLImageElement>(null);
    const [opacity, setOpacity] = React.useState(0);
    const [fadeIn, setFadeIn] = React.useState(false);

    React.useEffect(() => {
        // check if the image is already cached by the browser
        // complete=true if browser has cache
        const imageInCache = imgRef.current?.complete;

        if (!imageInCache) {
            setFadeIn(true);
        }
    }, []);

    return (
        // eslint-disable-next-line jsx-a11y/alt-text
        <img
            ref={imgRef}
            {...props}
            loading="lazy"
            style={{ opacity, transition: fadeIn ? '0.3s all ease-in' : undefined }}
            onLoad={() => {
                setOpacity(1);
            }}
        />
    );
};
